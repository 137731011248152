<template>
    <div>
        <a-layout style="min-height: 100vh">
            <a-layout-sider v-model:collapsed="collapsed" collapsible>
                <div class="logo" />
                <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
                    <a-menu-item key="1" ref="/dashboard">
                        <router-link to="/dashboard">
                            <pie-chart-outlined />
                            <span>Dashboard</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="2" ref="/task">
                        <router-link to="/task">
                            <OrderedListOutlined />
                            <span>Task</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="3" ref="/user">
                        <router-link to="/user">
                            <UserOutlined />
                            <span>User</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="4" ref="/spin">
                        <router-link to="/spin">
                            <PlayCircleOutlined />
                            <span>Mini Game</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="6" ref="/claim-histories">
                        <router-link to="/claim-histories">
                            <CheckOutlined />
                            <span>Mini Game Claim</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="5" ref="/pre-sale">
                        <router-link to="/pre-sale">
                            <GlobalOutlined />
                            <span>Setting</span>
                        </router-link>
                    </a-menu-item>
                </a-menu>
            </a-layout-sider>
            <a-layout>
                <a-layout-header style="background: #fff; padding: 0" />
                <a-layout-content style="margin: 0 16px">
                    <a-breadcrumb style="margin: 16px 0">
                        <a-breadcrumb-item>Home</a-breadcrumb-item>
                        <a-breadcrumb-item>{{ $route.name }}</a-breadcrumb-item>
                    </a-breadcrumb>
                    <div :style="{ padding: '24px', background: '#fff', minHeight: '100vh' }">
                        <router-view></router-view>
                    </div>
                </a-layout-content>
                <a-layout-footer style="text-align: center">
                    CatHunterX ©2024 Created by Digibazzy
                </a-layout-footer>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
import {
  PieChartOutlined,
  OrderedListOutlined,
  UserOutlined,
  PlayCircleOutlined,
  GlobalOutlined,
  CheckOutlined
} from '@ant-design/icons-vue';
export default {
    name: 'Container',
    components: {
        PieChartOutlined,
        OrderedListOutlined,
        UserOutlined,
        PlayCircleOutlined,
        GlobalOutlined,
        CheckOutlined
    }
}
</script>