<template>
    <a-card title="App Configuration">
        <a-upload v-model:file-list="fileList" name="avatar" list-type="picture-card" class="avatar-uploader"
            :show-upload-list="false" action="https://services.cathunter.xyz/admin/upload-image" @change="handleChange">
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
            <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">Upload Home Image</div>
            </div>
        </a-upload>
        <hr style="margin-top: 10px;">
        <a-form :model="congfig" :label-col="labelCol" :wrapper-col="wrapperCol" style="margin-top: 10px;">
            <a-form-item label="Primary Color">
                <a-input type="color" v-model:value="congfig.primaryColor" />
            </a-form-item>
            <a-form-item label="Time End">
                <a-date-picker v-model:value="congfig.timeLeft" format="YYYY-MM-DD" />
            </a-form-item>
            <a-form-item label="Quantity Sold">
                <a-input-number v-model:value="congfig.quantitySold" :min="0" />
            </a-form-item>
            <a-form-item label="Receiver Address">
                <a-input v-model:value="congfig.receiveAddress" />
            </a-form-item>
            <a-form-item label="Jetton Address">
                <a-input v-model:value="congfig.jettonAddress" />
            </a-form-item>
            <a-form-item label="Jetton Token Address">
                <a-input v-model:value="congfig.jettonTokenAddress" />
            </a-form-item>
            <a-form-item label="Wallet Mnemonic">
                <a-input v-model:value="congfig.walletMnemonic" />
            </a-form-item>
            <hr>
            <a-form-item label="Token Name" style="margin-top: 10px;">
                <a-input v-model:value="congfig.tokenName" />
            </a-form-item>
            <a-form-item label="Telegram Bot Token">
                <a-input v-model:value="congfig.telegramBotToken" />
            </a-form-item>
            <a-form-item label="Telegram Channel Link">
                <a-input v-model:value="congfig.telegramChannelLink" />
            </a-form-item>
            <a-form-item label="Telegram App Url">
                <a-input v-model:value="congfig.telegramAppUrl" />
            </a-form-item>
            <a-form-item label="Mini Game Fee">
                <a-input-number v-model:value="congfig.miniGameFee" :min="0" />
            </a-form-item>
            <a-form-item label="Random Click From">
                <a-input v-model:value="congfig.randomClickFrom" :min="0" />
            </a-form-item>
            <a-form-item label="Random Click To">
                <a-input v-model:value="congfig.randomClickTo" :min="0" />
            </a-form-item>
            <a-form-item label="Welcome Message">
                <a-textarea v-model:value="congfig.welcomeMessage" />
            </a-form-item>
            <div style="margin-bottom: 10px;text-align: right;">
                <a-button @click="addLevelSetting">Add Level</a-button>
            </div>
            <a-form-item v-for="(item, index) in congfig.levelSetting" :key="index" :label="'Level ' + (index + 1)">
                <a-input v-model:value="congfig.levelSetting[index].level" placeholder="Level" />
                <a-input-number style="margin-top: 10px;" v-model:value="congfig.levelSetting[index].price"
                    placeholder="Price" />
                <a-input-number style="margin-top: 10px;" v-model:value="congfig.levelSetting[index].max"
                    placeholder="Max" />
                <div style="margin-top: 10px;text-align: right;">
                    <a-button @click="deleteLevelSetting(index)" danger>Delete</a-button>
                </div>
            </a-form-item>
            <a-form-item style="margin-top: 10px;">
                <a-button type="primary" @click="saveConfig()">Update</a-button>
            </a-form-item>
        </a-form>
    </a-card>
</template>
<script>
import http from "@/http";
import moment from "moment";
import dayjs, { Dayjs } from 'dayjs';
export default {
    /* eslint-disable */
    name: "PreSale",
    data() {
        return {
            congfig: {
                timeLeft: dayjs('2015-06-06', "YYYY-MM-DD"),
                quantitySold: 0,
                receiveAddress: "",
                jettonAddress: "",
                jettonTokenAddress: "",
                walletMnemonic: "",
                telegramBotToken: "",
                telegramChannelLink: "",
                telegramAppUrl: "",
                welcomeMessage: "",
                levelSetting: [],
                miniGameFee: 0,
                tokenName: "",
                primaryColor: "#000000",
                randomClickFrom: 0,
                randomClickTo: 0
            },
            labelCol: { style: { width: '150px' } },
            wrapperCol: { span: 24 },
            fileList: [],
            imageUrl: "",
            loading: false,
        }
    },
    methods: {
        async handleChange(file) {
            this.imageUrl = await this.getBase64(file.file.originFileObj);
        },
        async getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        addLevelSetting() {
            this.congfig.levelSetting.push({ level: "", price: "", max: "" });
        },
        deleteLevelSetting(index) {
            this.congfig.levelSetting.splice(index, 1);
        },
        getPreSaleConfig() {
            http.get("/admin/setting/data", { headers: { Authorization: localStorage.getItem("hashing_hashing") }, params: { page: this.page } }).then(res => {
                if (res.status === 200) {
                    const data = res.data.data;
                    this.imageUrl = data.imageUrl ? data.imageUrl : "";
                    this.congfig = {
                        ...data,
                        levelSetting: data.levelSetting ? data.levelSetting.map(item => ({
                            level: item.level,
                            price: item.price,
                            max: item.max
                        })) : [],
                        timeLeft: data.timeLeft ? dayjs(moment(data.timeLeft).format('YYYY-MM-DD'), "YYYY-MM-DD") : dayjs('2015-06-06', "YYYY-MM-DD")
                    }
                }
            });
        },
        saveConfig() {
            http.post("/admin/setting/save", {
                timeLeft: this.congfig.timeLeft.format('YYYY-MM-DD'),
                quantitySold: this.congfig.quantitySold,
                receiveAddress: this.congfig.receiveAddress,
                jettonAddress: this.congfig.jettonAddress,
                jettonTokenAddress: this.congfig.jettonTokenAddress,
                walletMnemonic: this.congfig.walletMnemonic,
                telegramBotToken: this.congfig.telegramBotToken,
                telegramChannelLink: this.congfig.telegramChannelLink,
                telegramAppUrl: this.congfig.telegramAppUrl,
                welcomeMessage: this.congfig.welcomeMessage,
                levelSetting: this.congfig.levelSetting,
                miniGameFee: this.congfig.miniGameFee,
                tokenName: this.congfig.tokenName,
                primaryColor: this.congfig.primaryColor,
                randomClickFrom: this.congfig.randomClickFrom,
                randomClickTo: this.congfig.randomClickTo
            },
                { headers: { Authorization: localStorage.getItem("hashing_hashing") } }
            ).then(res => {
                if (res.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Updated!",
                        type: "success"
                    });
                }
            });
        }
    },
    mounted() {
        this.getPreSaleConfig();
    }
}
</script>
<style scoped>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.ant-upload img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>