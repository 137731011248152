<template>
    <div>
        <a-input-search v-model:value="search" :loading="loading" placeholder="Search user username"  enter-button @input="searchUser" />
        <div style="margin-top: 20px;">
            <a-table :scroll="{ x: 1100, y: 1000 }" :columns="columns" :data-source="users" :pagination="false">
                <template #bodyCell="{ column, record }">
                    <template v-if="column.dataIndex === 'address'">
                        <a-button type="primary" @click="copyAddress(record.address)">Copy Address</a-button>
                        <div style="margin-top: 10px;">{{ record.address }}</div>
                    </template>
                </template>
            </a-table>
        </div>
        <div style="margin-top: 20px;">
            <v-pagination v-model="page" :pages="pages" :range-size="1" active-color="#DCEDFF"
                @update:modelValue="changePage" />
        </div>
    </div>
</template>
<script>
import http from "@/http";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { debounce } from 'lodash';
export default {
    /* eslint-disable */
    name: "User",
    components: {
        VPagination
    },
    data() {
        return {
            search: "",
            loading: false,
            page: 1,
            users: [],
            totals: 8,
            pages: 1,
            columns: [
                {
                    title: 'Username',
                    dataIndex: 'username'
                },
                {
                    title: 'Balance',
                    dataIndex: 'balance'
                },
                {
                    title: 'Transaction',
                    dataIndex: 'totalTransactions'
                },
                {
                    title: 'Address',
                    dataIndex: 'address'
                },
                {
                    title: 'Spin',
                    dataIndex: 'totalSpins'
                },
                {
                    title: 'Refs',
                    dataIndex: 'totalRefs'
                }
            ]
        }
    },
    methods: {
        changePage() {
            this.getUsers();
        },
        copyAddress(address) {
            navigator.clipboard.writeText(address);
            this.$message.success('Address copied to clipboard');
        },
        searchUser: debounce(async function() {
            this.loading = true;
            await this.getUsers();
            this.loading = false;
        }, 1500),
        getUsers() {
            http.get("/admin/users", { headers: { Authorization: localStorage.getItem("hashing_hashing") }, params: { page: this.page, search: this.search } }).then(res => {
                if (res.data.status === 200) {
                    this.users = res.data.data;
                    this.pages = Math.ceil(res.data.totals / 10);
                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    mounted() {
        this.getUsers();
    }
}
</script>