<template>
    <div>
        <a-table :columns="columns" :data-source="users" :pagination="false">
            <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'username'">
                    <p>{{ record.username.username }}</p>
                </template>
                <template v-if="column.key === 'result'">
                    <div>{{ record.result }}</div>
                </template>
                <template v-if="column.key === 'type'">
                    <div v-if="record.type === 'spin'" style="color: #ffb648;">==> SPIN</div>
                    <div v-else  style="color: red;">==> CLAIMED</div>
                </template>
            </template>
        </a-table>
        <div style="margin-top: 20px;">
            <v-pagination v-model="page" :pages="pages" :range-size="1" active-color="#DCEDFF"
                @update:modelValue="changePage" />
        </div>
    </div>
</template>
<script>
import http from "@/http";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import moment from "moment";
export default {
    /* eslint-disable */
    name: "Spin",
    components: {
        VPagination
    },
    data() {
        return {
            page: 1,
            users: [],
            totals: 8,
            pages: 1,
            columns: [
                {
                    title: 'Username',
                    dataIndex: 'username',
                    key: 'username'
                },
                {
                    title: 'Result',
                    dataIndex: 'result',
                    key: 'result'
                },
                {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type'
                },
                {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date'
                }
            ]
        }
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        },
        changePage() {
            this.getUsers();
        },
        getUsers() {
            http.get("/admin/spins", { headers: { Authorization: localStorage.getItem("hashing_hashing") }, params: { page: this.page } }).then(res => {
                if (res.data.status === 200) {
                    this.users = res.data.data;
                    this.pages = Math.ceil(res.data.totals / 10);
                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    mounted() {
        this.getUsers();
    }
}
</script>