<template>
    <a-card title="Create Task">
        <a-form :model="task" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item label="Title" name="title"
                :rules="[{ required: true, message: 'Please input your username!' }]">
                <a-input v-model:value="task.title" />
            </a-form-item>
            <a-form-item label="Link" name="link" :rules="[{ required: true, message: 'Please input your username!' }]">
                <a-input v-model:value="task.link" />
            </a-form-item>
            <a-form-item label="Bonus" name="bonus"
                :rules="[{ required: true, message: 'Please input your username!' }]">
                <a-input type="number" v-model:value="task.bonus" />
            </a-form-item>
            <a-button type="primary" @click="onCreateTask()">Submit Task</a-button>
        </a-form>
    </a-card>
    <div style="margin-top: 20px;">
        <a-input-search v-model:value="search" :loading="loading" placeholder="Search task title"  enter-button @input="searchTask" />
    </div>
    <a-table :pagination="false" :scroll="{ x: 1900, y: 1000 }" style="margin-top: 20px;" :columns="columns"
        :data-source="tasks">
        <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'postBackUrl'">
                <a-tag color="green" v-if="!record.postBackUrl">Auto Approved</a-tag>
                <a-tag color="blue" @click="copyPostBackUrl(record.postBackUrl)" v-else>
                    <CopyOutlined /> {{ record.postBackUrl }}
                </a-tag>
            </template>
            <template v-if="column.key === 'status'">
                <a-tag color="green" v-if="record.status === 1">Active</a-tag>
                <a-tag color="red" v-else>Inactive</a-tag>
            </template>
            <template v-if="column.key === 'acction'">
                <a-button type="primary" @click="onUpdateTask(record.taskId, record.status === 1 ? 0 : 1)">Change
                    Status</a-button>
            </template>
        </template>
    </a-table>
    <div style="margin-top: 20px;">
        <v-pagination v-model="page" :pages="pages" :range-size="1" active-color="#DCEDFF"
            @update:modelValue="changePage" />
    </div>
</template>
<script>
import http from "@/http";
import { CopyOutlined } from '@ant-design/icons-vue';
import VPagination from "@hennge/vue3-pagination";
import { debounce } from 'lodash';
export default {
    /* eslint-disable */
    name: "task",
    components: {
        CopyOutlined,
        VPagination
    },
    data() {
        return {
            task: {
                title: "",
                link: "",
                bonus: 0
            },
            tasks: [],
            labelCol: { style: { width: '150px' } },
            wrapperCol: { span: 24 },
            columns: [
                {
                    title: 'Title',
                    dataIndex: 'title',
                    width: 200
                },
                {
                    title: 'Link',
                    dataIndex: 'link',
                    width: 200
                },
                {
                    title: 'Post back URL',
                    dataIndex: 'postBackUrl',
                    key: 'postBackUrl',
                    width: 450
                },
                {
                    title: 'Bonus',
                    dataIndex: 'bonus',
                    sorter: {
                        compare: (a, b) => a.bonus - b.bonus,
                        multiple: 2,
                    },
                    width: 100
                },
                {
                    title: "Clicked",
                    dataIndex: "clicked",
                    sorter: {
                        compare: (a, b) => a.clicked - b.clicked,
                        multiple: 3,
                    },
                    width: 100
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: 100
                },
                {
                    title: 'Action',
                    key: 'acction',
                    fixed: 'right',
                    width: 100
                }
            ],
            pages: 10,
            page: 1,
            totals: 0,
            search: "",
            loading: false
        }
    },
    methods: {
        searchTask: debounce(async function() {
            this.loading = true;
            await this.getTask();
            this.loading = false;
        }, 1500),
        changePage(page) {
            this.page = page;
            this.getTask();
        },
        copyPostBackUrl(url) {
            navigator.clipboard.writeText(url);
            this.$notify({
                title: "MESSAGE",
                text: "Copied!",
                type: "success"
            });
        },
        onUpdateTask(taskId, status) {
            http.post("/admin/task/update", {
                id: taskId,
                status: status
            },
                { headers: { Authorization: localStorage.getItem("hashing_hashing") } }
            ).then(res => {
                if (res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Updated!",
                        type: "success"
                    });

                    this.getTask();
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Error!",
                        type: "error"
                    });
                }
            });
        },
        onCreateTask() {
            if (!this.task.title || !this.task.link || !this.task.bonus) {
                this.$notify({
                    title: "MESSAGE",
                    text: "Please Fill Information!",
                    type: "error"
                });
                return;
            }

            http.post("/admin/task/create", {
                ...this.task
            },
                { headers: { Authorization: localStorage.getItem("hashing_hashing") } }
            ).then(res => {
                if (res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Created!",
                        type: "success"
                    });

                    this.getTask();
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: "Error!",
                        type: "error"
                    });
                }
            });
        },
        getTask() {
            http.get("/admin/tasks", {  
                headers: { Authorization: localStorage.getItem("hashing_hashing") },
                params: {
                    page: this.page,
                    pageSize: 10,
                    title: this.search
                }
            }).then(res => {    
                if (res.data.status === 200) {
                    this.tasks = res.data.data;
                    this.totals = res.data.totals;
                    this.pages = Math.ceil(this.totals / 10);
                }
            }); 
        }
    },
    mounted() {
        this.getTask();
    }
}
</script>