<template>
    <a-table :columns="columns" :data-source="claims" />
    <div class="claim_blocks">
        <div class="claim_list">
            <div class="claim_items" v-for="claim in claims" :key="claim">
                <div class="address_text">{{ claim.address }}</div>
                <div>{{ claim.cur }}</div>
                <div>Amount: {{ claim.before_bal }}</div>
                <div>
                    <span>Current Status: </span>
                    <span v-if="claim.status === 0">Pending</span>
                    <span v-if="claim.status === 1">Approved</span>
                    <span v-if="claim.status === 2">Rejected</span>
                </div>
                <div class="claim_action">
                    <button @click="copyAddress(claim.address)">Copy Address</button>
                    <button @click="handleStatus(claim.claimId, 1)">Approved</button>
                    <button @click="handleStatus(claim.claimId, 2)">Reject</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import http from "@/http";
export default {
    /* eslint-disable */
    name: "Claim",
    data() {
        return {
            claims: [],
            page: 1,
            columns: [
                {
                    title: 'User',
                    dataIndex: 'user',
                },
                {
                    title: 'Address',
                    dataIndex: 'address',
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Created At',
                    dataIndex: 'createdAt',
                },
            ]
        }
    },
    methods: {
        async copyAddress(addres) {
            try {
                await navigator.clipboard.writeText(addres);
                this.$notify({
                    title: "MESSAGE",
                    text: "COPIED",
                    type: "success"
                });
            } catch ($e) {
                this.$notify({
                    title: "MESSAGE",
                    text: "ERORR",
                    type: "error"
                });
            }
        },
        async getClaimHistories() {
            return http.get("/admin/claim/histories", {
                headers: { Authorization: localStorage.getItem("hashing_hashing") },
                params: { page: this.page }
            }).then(res => {
                if (res.data.status === 200) {
                    this.claims = res.data.data;
                }
            });
        },
        async handleStatus(id, status) {
            return http.post("/admin/claim/status", {
                claimId: id,
                status: status
            }, { headers: { Authorization: localStorage.getItem("hashing_hashing") } }).then(res => {
                this.getClaimHistories();
                if (res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "success"
                    });
                }
            });
        }
    },
    mounted() {
        this.getClaimHistories();
    }
}
</script>