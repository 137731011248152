import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Login from "@/components/Login.vue";
import Task from "@/components/Task.vue";
import User from "@/components/User.vue";
import Spin from "@/components/Spin.vue";
import PreSale from "@/components/PreSale.vue";
import Trading from "./components/Trading.vue";
import Claim from "./components/Claim.vue";
import Container from "./Container.vue";
const routes = [
     {
          path: "/",
          redirect: "/dashboard",
     },
     {
          path: "/login",
          name: "Login",
          component: Login,
     },
     {
          path: "/admin",
          name: "Container",
          component: Container,
          children: [
               {
                    path: "/dashboard",
                    name: "Dashboard",
                    component: Home
               },
               {
                    path: "/task",
                    name: "Task",
                    component: Task
               },
               {
                    path: "/user",
                    name: "User",
                    component: User
               },
               {
                    path: "/spin",
                    name: "Mini Game",
                    component: Spin
               },
               {
                    path: "/pre-sale",
                    name: "Setting",
                    component: PreSale
               },
               {
                    path: "/trading-push",
                    name: "TradingPush",
                    component: Trading
               },
               {
                    path: "/claim-histories",
                    name: "Claim",
                    component: Claim
               }
          ]
     }
];

const router = createRouter({
     history: createWebHistory(),
     routes,
});

export default router;