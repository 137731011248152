<template>
  <div>
    <notifications />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      collapsed: false,
      selectedKeys: ['1']
    }
  },
  methods: {
    checkActiveMenu(name) {
      if (this.$route.name && this.$route.name === name) {
        return true;
      } else {
        return false;
      }
    },
    checkHideLayout() {
      if (this.$route.name === 'Login') {
        return false;
      }
      return true;
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/style.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu-title-content {
  text-align: left;
}
</style>
