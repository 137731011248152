<template>
    <a-row>
        <a-col :span="24">
            <a-card hoverable style="min-width: 150px;max-width: 500px;margin: 35px auto;" title="Login Form">
                <a-space>
                    <a-input v-model:value="hashing" placeholder="Enter Hashing & Hashing" />
                    <a-button type="primary" @click="hashingClick()">Login</a-button>
                </a-space>
            </a-card>
        </a-col>
    </a-row>
</template>
<script>
import http from "@/http";
export default {
    /* eslint-disable */
    name: "login",
    data() {
        return {
            hashing: ""
        }
    },
    methods: {
        async hashingClick() {
            return http.post('/admin/login', {
                hashing: this.hashing
            }).then(res => {
                console.log(res);
                if (res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "success"
                    });

                    localStorage.setItem("hashing_hashing", res.data.hashing);
                    this.$router.push({ name: "Dashboard" });
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "error"
                    });
                }
            });
        }
    }
}
</script>