import axios from "axios";

axios.defaults.baseURL = "https://services.cathunter.xyz";

// Thêm interceptor để xử lý lỗi 401
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized! Redirecting to login...");
      // Ví dụ: Chuyển hướng đến trang đăng nhập
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axios;